<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- ========== Hero Section ========== -->
    <div class="gradient-x-overlay-sm-indigo position-relative overflow-hidden">
      <div class="container space-top-3 space-bottom-2 position-relative">
        <div class="row justify-content-lg-between align-items-lg-center">
          <div class="col-md-6">
            <div class="mb-5 mb-md-0">
              <span class="d-block small font-weight-bold text-cap mb-2">our solutions</span>
              <h1>AI Agent</h1>
              <p class="lead">Intelligent Automation. Tailored for Your Business.</p>
            </div>
          </div>

          <div class="col-md-6">
            <div class="w-90 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/ai-agent-2.svg">
            </div>
          </div>
        </div>
      </div>
      <figure class="w-35 position-absolute top-0 right-0 z-index-n1 mt-n11 mr-n11">
        <img class="img-fluid" src="@/assets/svg/components/half-circle-1.svg">
      </figure>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Overveiw Section ========== -->
    <div class="container space-2 space-lg-3">
      <div class="w-md-80 text-center mx-md-auto mb-5 mb-md-9">
        <span class="d-block small font-weight-bold text-cap mb-2">Overview</span>
        <h2>What is an AI Agent?</h2>
        <p>Unlock the transformative power of AI Agents to streamline operations and drive sustainable growth.</p>
      </div>

      <div class="row align-items-lg-center mb-6">
        <div class="col-md-6 order-md-1 mb-3">
          <div class="w-90 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/automation.svg">
          </div>
        </div>
        <div class="col-md-6">
          <h3>Intelligent Automation</h3>
          <p>Harness intelligent AI agents to automate tasks and boost overall operational efficiency, tailored to meet your unique business needs. Our AI Agents seamlessly integrate with your existing workflows, freeing up your team to focus on strategic initiatives.</p>
        </div>
      </div>

      <div class="row align-items-lg-center mb-6">
        <div class="col-md-6 mb-3">
          <div class="w-80 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/ai-infrastructure.svg">
          </div>
        </div>
        <div class="col-md-6">
          <h3>Advanced AI Infrastructure</h3>
          <p>Leverage our state-of-the-art AI gateway and cloud-native infrastructure to power your intelligent automation. Our scalable and secure solutions ensure optimal performance and seamless integration across your organization.</p>
        </div>
      </div>

      <div class="row align-items-lg-center mb-6">
        <div class="col-md-6 order-md-1 mb-3">
          <div class="w-90 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/ai-service.svg">
          </div>
        </div>
        <div class="col-md-6">
          <h3>Specialized AI Services</h3>
          <p>Benefit from our specialized expertise in Retrieval-Augmented Generation (RAG), large language model fine-tuning, and AI safety guardrails. We help you harness the power of advanced AI technologies while ensuring responsible and ethical deployment.</p>
        </div>
      </div>

    </div>
    <!-- ========== End Overview Section ========== -->

    <!-- ========== More Solutions Section ==========-->
    <div class="space-2 space-lg-3 gradient-x-overlay-sm-indigo">
      <div class="position-relative">
        <div class="container">
          <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5">
            <span class="d-block small font-weight-bold text-cap mb-2">Don't Want to Manage on Your Own?</span>
            <h2>Ready-Made API-First SaaS Solutions</h2>
          </div>
          <div class="row w-lg-75 mx-auto">
            <div class="col-12 col-md-6 mb-4">
              <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fabrixai' }">
                <div class="card-img-top position-relative">
                  <img class="card-img-top" src="@/assets/img/products/products-fabrixai-thumbnail.jpg">
                </div>
                <div class="card-body">
                  <h3>AI Agent Platform</h3>
                  <p><strong>FabriXAI</strong> is an AI agent platform for both tech and non-tech users.</p>
                </div>
                <div class="card-footer border-0 pt-0">
                  <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </router-link>
            </div>
            <div class="col-12 col-md-6 mb-4">
              <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/taptab' }">
                <div class="card-img-top position-relative">
                  <img class="card-img-top" src="@/assets/img/products/products-taptab-thumbnail.jpg">
                </div>
                <div class="card-body">
                  <h3>AI Agents for Education</h3>
                  <p><strong>TapTab</strong> is the AI agents designed for the education sector.</p>
                </div>
                <div class="card-footer border-0 pt-0">
                  <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Solutions Section ========== -->

    <!-- ===== CTA Section ===== -->
    <div class="position-relative overflow-hidden text-center">
      <div class="container space-2">
        <div class="mb-6">
          <h2>Revolutionize Your Business with AI Agents</h2>
          <p>Contact Us to explore how our solutions can revolutionize your business.</p>
        </div>
        <router-link class="btn btn-primary transition-3d-hover btn-wide" :to="{ path: '/contact' }">Contact Us</router-link>
      </div>
    </div>
    <!-- ===== End CTA Section ===== -->

  </main>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import HSGoTo from '../../../assets/vendor/hs-go-to/src/js/hs-go-to'
import '../../../assets/js/hs.core.js'
import '../../../assets/js/hs.slick-carousel.js'
import '../../../assets/vendor/slick-carousel/slick/slick.js'
import { mapGetters } from 'vuex'
export default {
  name: 'AiAgent',
  data() {
    return {
      api_partner_list: []
    }
  },
  computed: {
    ...mapGetters([
      'partners'
    ])
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
  },
  created() {
    this.api_partner_list = this.$store.getters.partners.api_partner_list
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'AI Agent | Solutions | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/solutions/ai-agent' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'AI Agent | Solutions | beNovelty' },
        { property: 'og:description', content: 'Discover the AI Agents Solutions offered by beNovelty.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/solutions/ai-agent' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/slick-carousel/slick/slick.css");
</style>

<style>
.gradient-x-overlay-sm-indigo::before {
  background-image: linear-gradient(45deg, transparent, rgba(4, 214, 214, 0.082));
  background-repeat: repeat-x;
}
</style>
